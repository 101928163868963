

















































import { Component, Vue } from "vue-property-decorator";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required, email } from "vuelidate/lib/validators";
import { namespace } from "vuex-class";
import _ from "lodash";

const PasswordModule = namespace("password");

@Component({
  components: {
    Validation,
  },
})
export default class ForgotPassword extends Vue {
  public name = "ForgotPassword";

  @PasswordModule.Action("requestUserPasswordChange")
  public requestUserPasswordChange: any;

  @PasswordModule.Getter("getSuccess")
  public requestSuccess: any;

  public email = "";

  @Validations()
  public validations(): Record<string, any> {
    return {
      email: { required, email },
    };
  }

  public onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    this.requestUserPasswordChange(this.email);
  }
}
